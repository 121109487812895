import React from 'react'

function Free() {
  return (
    <div>
        <div className='pt-10 pb-9'>

        </div>
    </div>
  )
}

export default Free;